<template>
  <TunnelLayout>
    <template slot="form">
      <div class="flex flex-col justify-around my-0 mx-auto sm:w-full sm:px-8">
        <h2 class="font-bold text-2xl mb-4 sm:text-base text-promy-blue-300">
          Vérifions ensemble votre adresse :
        </h2>
        <div class="grid grid-cols-4 gap-2 items-baseline">
          <pro-auto-complete
            is_auto_complete_cross_api
            placeholder="Recherche"
            class="col-span-3 lg:col-span-full"
            bordered
            :list="autocomplete_items"
            v-model="form.informations.adresse"
            :args="['properties', 'label']"
            @setItem="setItemForAutocomplete"
            :is_loading="is_autocomplete_fetching"
            :is_searchable="true"
            :rules="'required'"
            typeInput="tunnel"
          />
          <pro-button
            class="col-span-1 lg:col-span-full"
            @click="StepTwo"
            btn="primary"
            :disabled="is_invalide"
          >
            C’est parti
          </pro-button>
        </div>
      </div>
    </template>
    <template slot="right-side">
      <div class="flex flex-col relative">
        <div
          v-if="
            !form.informations.current_parcelle &&
            map_is_loaded &&
            !cadastre.is_api_loading &&
            form.informations.code_insee
          "
          class="font-bold text-white bg-promy-orange-100 p-6 rounded-full w-fit sm:w-full absolute bottom-11 left-1/2 transform -translate-x-1/2 text-center sm:py-4 z-50"
        >
          Merci de sélectionner une parcelle
        </div>

        <Map
          :showBtnParcelle="true"
          :form="form"
          :cadastre="cadastre"
          ref="mapTunnelRevelationPotentiel"
          @mapLoaded="map_is_loaded = true"
          @updateAddress="updateAddress"
        />
      </div>
    </template>
  </TunnelLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import Map from './components/Map.vue'
import helpers from '@/mixins/helpers'
import parcelle from '@/mixins/parcelle'
export default {
  components: {
    Map,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    invalid: Boolean,
  },
  mixins: [helpers, parcelle],
  data() {
    return {
      autocomplete_items: [],
      is_autocomplete_fetching: false,
      AddressIsSelecting: false,
      prevRoute: null,
      map_is_loaded: false,
      is_updating_address: false,
    }
  },
  computed: {
    disabledButton() {
      if (this.$route.params.id_revelation) {
        return false
      }
      if (this.invalid || !this.user.can_create_reveler) {
        return true
      }
    },
    is_invalide() {
      return (
        this.invalid ||
        !this.form.informations.current_parcelle ||
        this.cadastre.is_api_loading
      )
    },
    ...mapGetters({
      parcelles_autour: 'parcelle/parcelles_autour',
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      current_batiments: 'parcelle/current_batiments',
      address_cross_gu: 'address/address',
      user: 'auth/user',
    }),

    adresse: {
      get() {
        return this.$store.getters['Tunnel/GET_ADRESSE']
      },
      set(value) {
        this.$store.commit('Tunnel/SET_ADRESSE', value)
      },
    },
    parcelles_selected_in_revelation() {
      return this.form.informations.current_parcelle &&
        Object.keys(this.form.informations.current_parcelle).length !== 0
        ? [
            this.form.informations.current_parcelle,
            ...this.form.informations.parcelles_voisines,
          ]
        : []
    },
  },
  created() {
    if (
      this.$route.params.id_revelation &&
      this.cadastre.is_loaded_cross_revelation
    ) {
      this.cadastre.is_api_loading = true
      let ids_parcelles_selected = [
        this.form.informations.current_parcelle.properties.id,

        ...this.form.informations.parcelles_voisines.map(
          (parcelle) => parcelle.properties.id,
        ),
      ]
      this.$http
        .post('/grand-publics/adresses/no-auth', {
          information: {
            geometry: {
              coordinates: this.form.informations.coords_parcelle_principale,
              type: 'Point',
            },
            properties: {
              label: this.form.informations.adresse,
              information: null,
              lat: this.form.informations.coords_parcelle_principale[1],
              lon: this.form.informations.coords_parcelle_principale[0],
              citycode: this.form.informations.code_insee,
              context: null,
            },
            type: 'Feature',
          },
          ids_parcelles_selected: ids_parcelles_selected.length
            ? ids_parcelles_selected
            : null,
        })
        .then((response_cadastre) => {
          let cadastre_principal =
            response_cadastre.data.parcelles.cadastre_principal
          this.cadastre.data = !Array.isArray(cadastre_principal)
            ? [cadastre_principal]
            : cadastre_principal
          this.cadastre.is_api_loading = false
        })
    }
  },
  async mounted() {
    if (!this.form.informations.adresse && this.address_cross_gu) {
      this.form.g_p_adresse_id = this.$route.query.id_address
        ? this.$route.query.id_address
        : null
      let address = { ...this.address_cross_gu }
      this.cadastre.is_api_loading = true
      this.AddressIsSelecting = true
      this.form.informations.adresse = address
        ? address.information.properties.label
        : null
      this.adresse = this.form.informations.adresse
      this.form.informations.coords_parcelle_principale =
        address.information.geometry.coordinates
      this.cadastre.is_loaded_cross_gu = false
      this.cadastre.is_loaded_cross_revelation = false
      this.form.informations.current_parcelle = {
        ...this.current_parcelle,
      }
      this.form.informations.parcelles_voisines = [...this.parcelles_voisines]
      // this.cadastre.is_loaded_cross_gu = true
      this.setInformationCrossApis(address.information)
    } else if (
      this.$route.query.adresse &&
      !this.form.informations.code_postal
    ) {
      await this.fetchAdresses()
      this.autocomplete_items.length > 0 &&
        this.setItemForAutocomplete(this.autocomplete_items[0])
    }
  },
  methods: {
    StepTwo() {
      let params = this.$route.query.from_europe1
        ? {
            adresse: this.$route.query.adresse,
            email: this.$route.query.email,
            tel: this.$route.query.tel,
            from_europe1: this.$route.query.from_europe1,
          }
        : {}

      this.$store.commit('Tunnel/SET_STEP_NUMBER', 2)
      this.$router.push({
        path: `/revelation/steps/2/${this.$route.params.id_revelation ?? ''}`,
        query: { id_address: this.$route.query.id_address, ...params },
      })
    },
    setItemForAutocomplete(adresse = null) {
      this.cadastre.is_api_loading = true
      this.AddressIsSelecting = true
      this.form.informations.adresse = adresse ? adresse.properties.label : null
      this.adresse = this.form.informations.adresse
      this.form.informations.coords_parcelle_principale =
        adresse.geometry.coordinates
      this.form.informations.current_parcelle = null
      this.form.informations.parcelles_voisines = []

      this.setInformationCrossApis(adresse)
      this.cadastre.is_loaded_cross_gu = false
      this.cadastre.is_loaded_cross_revelation = false
    },
    searchAfterDebounce: _.debounce(
      function () {
        this.fetchAdresses()
      },
      800, // 800 milliseconds
    ),
    async fetchAdresses() {
      this.is_autocomplete_fetching = true
      let response = await this.$http.get('grand-publics/gouv-url-reveler', {
        params: {
          limit: 20,
          q: this.form.informations.adresse,
          without_house_number: true,
        },
      })
      this.autocomplete_items = []
      if (response.data.hasOwnProperty('features')) {
        this.autocomplete_items = response.data.features
      }
      this.is_autocomplete_fetching = false
    },

    setInformationCrossApis(adresse) {
      this.cadastre.error_parcelle_not_found = null
      this.cadastre.is_api_loading = true
      let ids_parcelles_selected = this.parcelles_selected_in_revelation.map(
        (parcelle) => parcelle.properties.id,
      )
      this.$http
        .post('/grand-publics/adresses/no-auth', {
          information: adresse,
          ids_parcelles_selected: ids_parcelles_selected.length
            ? ids_parcelles_selected
            : null,
        })
        .then((response_cadastre) => {
          this.$http
            .get('/grand-publics/get-data-urbanisme-from-parcelles', {
              params: {
                ids_parcelles: ids_parcelles_selected.length
                  ? ids_parcelles_selected
                  : [
                      response_cadastre.data.parcelles.cadastre_principal
                        .parcelle.properties.id,
                    ],
              },
            })
            .then((response_urbanisme) => {
              let cadastre_principal =
                response_cadastre.data.parcelles.cadastre_principal
              this.cadastre.data = !Array.isArray(cadastre_principal)
                ? [cadastre_principal]
                : cadastre_principal
              let statistiques_commune =
                response_cadastre.data.statistiques_commune
              let zones = {
                features: response_urbanisme.data.urbanisme_parcelles
                  .map((item) => item?.zone_urba?.features)
                  .filter((el) => el)
                  .flat(),
                type: 'FeatureCollection',
              }

              this.form.informations.code_insee =
                statistiques_commune.code_insee
              this.form.informations.code_postal =
                statistiques_commune.code_postal
              this.form.informations.departement =
                statistiques_commune.departement
              this.form.informations.region = statistiques_commune.region
              zones.features.map((feature) => {
                feature.properties = this.convertAttributesToLowerCase(
                  feature.properties,
                )
              })
              this.form.informations.zonage_plu = this.removeDuplicateFeature(
                zones.features,
              )

              let own_new_parcelles = this.cadastre.data.map(
                (item) => item.parcelle,
              )
              this.form.informations.current_parcelle =
                this.getParcellePrincipalThroughCenterOfAllParcelles(
                  own_new_parcelles,
                )
              this.form.informations.parcelles_voisines =
                own_new_parcelles.filter(
                  (parcelle) =>
                    parcelle.properties.id !==
                    this.form.informations.current_parcelle.properties.id,
                )
              this.cadastre.is_api_loading = false
            })
        })
        .catch((err) => {
          this.cadastre.error_parcelle_not_found =
            'Informations de la parcelle non disponibles'
        })
    },
    setInformationCrossApiCadastre(information) {
      this.cadastre.data = information
      this.form.informations.code_insee = information.code_insee
      this.form.informations.code_postal = information.code_postal
      this.form.informations.departement = information.departement
      this.form.informations.region = information.region
      this.form.informations.zonage_plu = information.zones_urbanisme
      this.cadastre.is_api_loading = false
    },
    updateAddress(coords) {
      this.is_updating_address = true
      this.$http
        .get('grand-publics/gouv-url/reverse/no-auth', {
          params: {
            lon: coords[0],
            lat: coords[1],
          },
        })
        .then((response) => {
          try {
            let adresse = response.data.features[0]
            let properties = adresse.properties
            this.form.informations.adresse = properties.label
            this.form.informations.code_insee = properties.citycode
            this.form.informations.code_postal = properties.postcode
            this.form.informations.coords_parcelle_principale =
              adresse.geometry.coordinates.map((coord) => coord.toString())
            this.is_updating_address = false
          } catch (error) {
            console.error(error)
            this.is_updating_address = false
          }
        })
    },
  },
  watch: {
    'form.informations.adresse': {
      handler() {
        if (!this.AddressIsSelecting) {
          this.searchAfterDebounce()
        }
        this.AddressIsSelecting = false
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
}
</script>
